.modal-bg{position: fixed;top: 0;right: 0;bottom: 0;left: 0; background: rgba(40,106,45,0.9); z-index: 99999;
    display: none;}
.modal{position: relative; top: 40%; width: 350px; height: 200px; background: transparent; margin: 0 auto; }
.modal-icon{cursor: pointer; position: absolute; right: 10px; top: 10px;}
.modal-content{display: flex;flex-direction: column;align-items: center; justify-content: center; height: 100%;}
.check-icon{padding: 20px 0px;}
.show-modal{display: block;}
.center{color: #000000; text-align: center;}

@media (max-width: 544px){
    .modal-content{padding: 0px 15px;}
    .center{font-size: 14px;}
}