/*Home*/
#home{background-color: #cccccc;}
.video{width: 100%;height: 100vh;position: relative;overflow: hidden;}
iframe {width: 100%;height: 56vw;}
.footer-menu{display: flex; flex-direction: row; justify-content: space-around; position: absolute; bottom: 0px; width: 100%; padding: 0; margin: 0;}
.footer-menu li {background-color: #307E36; width: 50%;  padding: 20px 0px; text-align: center; cursor: pointer;}
.footer-menu li:first-child{background-color: #286A2D;}
.footer-menu li a{color: #73C94D; font-size: 15px; font-weight: 700; text-transform: uppercase; letter-spacing: 10px; text-decoration: none; cursor: pointer;}
.footer-menu li a:hover{letter-spacing: 15px;}
/*Playball*/
#playball-grama{background-color: #033f77;}
.playball-content{display: grid; grid-template-columns: repeat(2, 1fr); align-items: center; height: 100vh;}
.info-text{align-self: center;}
.info-text h1{color: #B4DCF2; font-size: 26px; line-height: 36px; font-weight: 600; max-width: 580px;}
.info-text p{color: #B4DCF2; font-size: 15px; line-height: 25px; font-weight: 500; max-width: 90%;}
.forms img{width: 50%;}
.form1 {position: relative;top: 72px;}
.form2 {position: relative; top: -84px;}
.form3 {position: relative; top: 183px; left: -166px;}
.form4 {position: relative; top: 25px; left: -166px;}
/*Parallax*/

.parallax-content{display: flex; align-items: center; justify-content: center; height: 506px; background: rgba(42, 58, 75, 0.5);}
#parallax h2{color: #FFFFFF; text-transform: uppercase; font-weight: 600; font-size: 28px; line-height: 38px; letter-spacing: 5px;}
/*Projetos*/
#projetos{background-color: #033f77;}
#projetos h2{color: #B4DCF2; font-weight: 600; font-size: 24px; line-height: 34px; padding: 50px 0px 20px 0px; margin: 0px;}
.projetos-content{display: grid; grid-template-columns: repeat(3, 1fr); align-items: center;}
.projetos-content img{object-fit: cover; width: 100%; max-height: 100%; display: block;}
#gramas-esportivas{background-image: url('../../assets/grama-esportiva.jpg'); background-size: 100%; background-repeat: no-repeat; height: 600px;}
.gramas-decorativas{background-image: url('../../assets/grama-decorativa.jpg'); background-size: 100%; background-repeat: no-repeat; height: 600px;}
#projetos-especiais{background-image: url('../../assets/projetos-especiais.jpg'); background-size: 100%; background-repeat: no-repeat; height: 900px;}
.filter{background: rgba(13, 23, 35, 0.7); height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column;}
.filter-mobile{height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column;}
#gramas-decorativas .filter h1{padding-top: 50px;}
#gramas-esportivas .filter h1, #gramas-decorativas .filter h1, #projetos-especiais .filter h1{font-weight: 700; color: #73C94D; text-transform: uppercase; text-align: center; font-size: 5.2em; line-height: 57px; margin: 0px;}
#gramas-esportivas .filter h2, #gramas-decorativas .filter h2{font-weight: 600; color: #FFFFFF !important; text-transform: uppercase; margin: 0px; padding: 0px !important;}
#gramas-esportivas .filter p, #gramas-decorativas .filter p, #projetos-especiais .filter p{color: #ffffff; font-size: 16px; line-height: 26px; font-weight: 500; text-align: center;}
/*Slides*/
#slides, .gramas-decorativas-info{background-color: #286A2D; padding: 60px 0px 0px 0px;}
.gramas-decorativas-info{background-color: #307E36;}
.slides-info, .decorativa-info{background-color: #218328; width: 1440px; margin: 0 auto; border-radius: 18px;}
.slides-info h3{color: #ffffff; font-weight: 600; font-size: 20px; line-height: 30px; text-align: center; padding: 30px 0px 20px 0px; margin: 0px;}
.list{display: flex; flex-direction: row; align-items: center; justify-content: space-around;}
.list div img{width: 40px; height: auto; margin: 0px auto; display: block;}
.list div span{color: #ffffff; font-weight: 600; font-size: 14px; line-height: 24px; text-align: center; display: block; padding-bottom: 20px;}
.slides-info2, .decorativa-info2{background-color: #235C27; width: 520px; margin: 0 auto; border-bottom-left-radius: 18px; border-bottom-right-radius: 18px; margin-bottom: 80px;}
.slides-info2 h4{color: #ffffff; font-weight: 600; font-size: 15px; line-height: 25px; text-align: center; text-transform: uppercase; padding: 10px 0px 0px 0px; margin: 0px;}
.slides-info2 p {color: #ffffff; font-weight: 500; font-size: 11px; text-align: center; text-transform: uppercase; margin: 0px;  padding-bottom: 10px;}
.slides-content{display: flex; flex-direction: row; align-items: center; justify-content: space-around;}
.slides-content div {text-align: center; width: 500px;}
.slides-content div h3{font-weight: 700;color: #73C94D;text-transform: uppercase; font-size: 18px;line-height: 36px;margin: 0px;}
.slides-content div span{font-weight: 700;color: #73C94D;text-transform: uppercase; font-size: 37px;line-height: 10px;margin: 0px; display: block;}
.slides-content img{width: 70%; display: block; margin: 0px auto; padding: 20px 0px;}
.slides-content div ul{display: flex;align-items: center;flex-direction: column; padding: 20px 0px 0px 0px;}
.slides-content div ul li{color: #ffffff; font-size: 18px; text-transform: uppercase;}
.slides-content div a{color: #136019; font-weight: 600; font-size: 15px; line-height: 22px; text-decoration: none; text-transform: uppercase; 
background-color: #28AA41; border-radius: 60px; margin: 13px auto; width: 60%; height: 40px; display: flex; flex-direction: row; align-items: center; justify-content: center; cursor: pointer;}
.slides-content div a:hover{background-color: #235C27; color: #ffffff;}
.slides-content div a:hover svg{color: rgb(255, 255, 255) !important;}
.slides-content div svg{padding-right: 5px;}
.swiper-pagination-bullet{display: inline-block !important;}
.swiper-pagination-bullets{bottom: 30px !important;}
.swiper-pagination-bullet-active{background: #ffffff !important;}
.swiper-pagination-bullet{background: #ffffff !important;}
.grama-esportiva-gallery, .grama-esportiva-gallery2{padding: 60px 0px 0px 0px; display: flex; flex-wrap: wrap; background-color: #286A2D;}
.grama-esportiva-gallery2{background-color: #307E36;}
.grama-esportiva-gallery div{height: 373px; flex-grow: 1;}
.grama-esportiva-gallery2 div {height: 330px;}
.grama-esportiva-gallery div img{max-height: 100%;min-width: 100%;object-fit: cover;vertical-align: bottom;}
.decorativa-info{width: 200px; position: relative; z-index: 1;}
.decorativa-info2{border-radius: 18px; padding: 20px 0px 0px 0px; position: relative; top: -20px;}


/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px){
.form2 {top: -69px;}
.form3{top: 173px; left: -140px;}
.form4{top: 40px; left: -140px;}
.parallax-content{height: 365px;}
#gramas-esportivas, .gramas-decorativas, #projetos-especiais{height: 420px;}
.slides-info {width: 1200px;}
.decorativa-info{width: 200px !important;}
.grama-esportiva-gallery div {height: 280px;}
.grama-esportiva-gallery2 div {height: 247px !important;}
}

@media (min-width: 1200px) and (max-width: 1399px){
.form2 {top: -40px;}
.form3{top: 159px; left: -120px;}
.form4{top: 45px; left: -120px;}
.parallax-content{height: 305px;}
#gramas-esportivas, .gramas-decorativas, #projetos-especiais{height: 420px;}
.slides-info {width: 1000px;}
.decorativa-info{width: 200px !important;}
.grama-esportiva-gallery2 div {height: 490px;}
}

@media (min-width: 992px) and (max-width: 1199px){
.playball-content{align-items: flex-start;}
.info-text {align-self: flex-start;padding-top: 80px;}
.forms img {width: 60%;}
.form2 {top: -380px;}
.form3{top: -155px; left: 177px;}
.form4{top: -605px; left: 177px;}
.parallax-content{height: 255px;}
#gramas-esportivas, .gramas-decorativas, #projetos-especiais{height: 351px;}
.slides-info {width: 900px;}
.decorativa-info{width: 200px !important;}
.grama-esportiva-gallery2 div {height: 405px;}
}

@media (min-width: 768px) and (max-width: 991px){
.br_mobile{display: none;}
.playball-content{align-items: flex-start;}
.info-text {align-self: flex-start;padding-top: 80px;}
.info-text h1{font-size: 20px; line-height: 30px;}
.forms img {width: 69%;}
.form1{left: -36px;}
.form2 {top: -300px; left: -36px;}
.form3{top: -162px; left: 140px;}
.form4{top: -530px; left: 140px;}
.parallax-content{height: 195px;}
#gramas-esportivas, .gramas-decorativas, #projetos-especiais{height: 270px;}
#gramas-esportivas .filter h1, #gramas-decorativas .filter h1, #projetos-especiais .filter h1{font-size: 4.2em;}
.slides-info {width: 700px;}
.decorativa-info{width: 200px !important;}
.slides-content div a{width: 60%;}
.grama-esportiva-gallery div img{width: 100%;}
.grama-esportiva-gallery div {height: 337px;}
.grama-esportiva-gallery2 div {height: 311px !important;}
}

@media (max-width: 767px){
.br_mobile{display: none;}
.playball-content{grid-template-columns: 1fr;}
.info-text h1, .info-text p{max-width: 100%;}
.info-text h1{font-size: 1.3em; line-height: 1.6em;}
.forms{display: grid; grid-template-columns: repeat(4, 1fr);}
.forms img {width: 100%;}
.form1{top: 0px; left: 0px;}
.form2 {height: auto; top: 0px; left: 0px;}
.form3{top: 0px; left: 0px;}
.form4{top: 0px; left: 0px;}
.parallax-content{height: 195px;}
#gramas-esportivas, .gramas-decorativas, #projetos-especiais{height: 268px; background-size: cover; text-align: center;}
#gramas-esportivas .filter h1, #gramas-decorativas .filter h1, #projetos-especiais .filter h1{font-size: 3.2em;}
.slides-info {width: 700px;}
.decorativa-info{width: 200px !important;}
.slides-content div a{width: 60%;}
.grama-esportiva-gallery div img{width: 100%;}
.grama-esportiva-gallery div {height: 337px;}
.grama-esportiva-gallery2 div {height: 311px !important;}
}

@media (max-width: 544px){
.bg-mobile{background-image: url('../../assets/mobile-bg.jpg'); background-size: cover; background-position: center; height: 100%; background-repeat: no-repeat;}
iframe{position: absolute;top: 0;left: 0;width: 100%;height: 100%;}
#video-desk{display: none;}
.mobile-video-modal{display: none; position: fixed;top: 0;right: 0;bottom: 0;left: 0; background: #00000066; z-index: 99999;}   
.show-modal{display: block;}
.modal{position: relative; top: 200px; width: 90%; height: 230px; margin: 0 auto; text-align: right;}
.close-icon{cursor: pointer; position: relative; z-index: 999999;}
.close-icon path{stroke: #ffffff !important;}
.footer-menu{display: none !important;}
.menu-mobile{display: block !important; z-index: 9; position: fixed;}
.menu-mobile li{width: 100%; padding: 5px 0px; background-color: #28aa40 !important;}
.menu-mobile li a{display: inline-flex; align-items: flex-end; color: #17501b;}
.slides-info {width: 90%;}
.slides-content img {width: 100%;}
.slides-info2, .decorativa-info2{width: 70%;}
.slides-content div{width: 100% !important;}
.forms{grid-template-columns: repeat(2, 1fr);}
.projetos-content{grid-template-columns: 1fr;}
.parallax-content {height: 125px; text-align: center;}
#gramas-esportivas .filter h1, #gramas-decorativas .filter h1, #projetos-especiais .filter h1{font-size: 3.5em; line-height: 45px; padding-top: 0px;
padding-bottom: 30px;}
.hide{display: none;}
.grama-esportiva-gallery div {height: auto;}
.grama-esportiva-gallery2 div {height: auto !important;}
.slides-content{flex-direction: column;}
.playball-content{height: auto;}
.footer-menu li a{font-size: 18px; letter-spacing: 2px;}
.footer-menu li a:hover {letter-spacing: 2px !important;}
.info-text h1 {font-size: 1em;line-height: 1.6em; padding-top: 120px;}
.info-text p{font-size: 12px; line-height: 24px;}
.whats-mobile{padding-right: 10px;}
#parallax h2, #projetos h2{font-size: 18px;line-height: 28px;}
#gramas-esportivas .filter p, #gramas-decorativas .filter p, #projetos-especiais .filter p{font-size: 14px;line-height: 24px;max-width: 95%;
    padding-bottom: 5px;}
#gramas-esportivas, .gramas-decorativas, #projetos-especiais {height: 480px;background-size: cover;text-align: center;}
.mobile {display: grid;grid-template-columns: 0.1fr 1fr;gap: 60px;}
.logo img{width: 130%;}
}

@media (max-width: 320px){
.grama-esportiva-gallery div {height: 143px;}
.grama-esportiva-gallery2 div {height: 127px !important;}
#gramas-esportivas .filter h1, #gramas-decorativas .filter h1, #projetos-especiais filer h1 {font-size: 1.8em; line-height: 25px; padding-bottom: 10px}
#gramas-esportivas .filter h2, #gramas-decorativas .filter h2{font-size: 17px;}
#gramas-esportivas, .gramas-decorativas , #projetos-especiais{height: 380px;background-size: cover;text-align: center;}
.slides-info2, .decorativa-info2 {width: 100%;}
.mobile {display: grid;grid-template-columns: 0.1fr 1fr;gap: 48px;}
.logo img{width: 100%;}
}

