/*Footer*/
footer{background-image: url('../../assets/form_back.jpg'); background-size: 100%; background-repeat: no-repeat; height: auto; position: relative;
    z-index: 9;}
footer h1{color: #ffffff; font-size: 18px; line-height: 28px; font-weight: 600; text-align: center; margin: 0; padding: 40px 0px 0px 0px;}
.footer-filter{background: rgba(42, 58, 75, 0.5); height: 100%;}
.contact-form{width: 30%; margin: 0 auto; text-align: center; padding-bottom: 60px;}
.medium{display: flex; align-items: center; justify-content: space-between}
.medium div{padding: 20px 0px 0px 0px !important; width: 48%;}
.medium div input, .medium div select{border: 1px solid #ffffff !important;}
.medium2{display: flex; align-items: center; justify-content: space-between}
.medium2 div{padding: 20px 0px !important; width: 48%;}
.medium2 div input, .medium2 div select{border-bottom: 1px solid #ffffff !important; text-transform: uppercase; font-size: 11px;}
.contact-form div{padding: 10px 0px;}
.contact-form div label{display: block; font-size: 11px; line-height: 24px; font-weight: 500; color: #ffffff; text-transform: uppercase; text-align: left;}
input, select{background:transparent; border-bottom: 1px solid #ffffff !important; border: 0; height: 27px; color: #ffffff; width: 100%; box-sizing: border-box;}
select option{background: #28AA41;}
button{cursor: pointer;}
textarea{background:transparent; border: 1px solid #ffffff !important; border: 0; height: 27px; color: #ffffff; width: 100%; box-sizing: border-box; height: 200px;}
::-webkit-input-placeholder {color: #ffffff; font-size: 11px; font-weight: 500; text-transform: uppercase;}
:-ms-input-placeholder {color: #ffffff; font-size: 11px; font-weight: 500; text-transform: uppercase;}
::placeholder {color:#ffffff; font-size: 11px; font-weight: 500; text-transform: uppercase;}
.contact-form button{background-color: #28AA41; color: #ffffff; font-size: 13px; font-weight: 600; text-transform: uppercase; border-radius: 18px; border: none; padding: 8px;
width: 25%;}
.footer-info{border-top: 1px solid #34842E; padding: 5px 0px;}
.footer-info p{color: #4EE66C; font-size: 13px; line-height: 23px; font-weight: 600; display: flex; align-items: center; justify-content: center;}
.footer-info p svg{padding-right: 5px;}
#WhatsAppFixo {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
}
#WhatsAppFixo #TextoChamada {
    float: left;
    margin-top: 7px;
    background-color: #fff;
    color: #606060;
    padding: 15px;
    border: 1px solid #ededed;
    border-radius: 3px;
    font-family: arial;
    font-size: 14px;
    box-shadow: 0px 2px 10px #ccc;
}

#TextoChamada {
    display: none;
}

#WhatsAppFixo:hover #TextoChamada {
    display: block;
}

#WhatsAppFixo a img {
    width: 60px;
    height: 60px;
    border-radius: 60px;
}

/*Responsivo*/
@media (min-width: 1200px) and (max-width: 1399px){
footer{background-size: 100% 100%;}
.contact-form {width: 60%;}
}

@media (min-width: 320px) and (max-width: 1199px){
footer{background-size: 100% 100%;}  
.contact-form {width: 80%;}
#WhatsAppFixo{display: none;}
}

@media (max-width: 544px){
footer{background-size: auto;}
footer h1{font-size: 24px; line-height: 27px; padding-top: 120px;}
.contact-form {width: 100%;}
.medium, .medium2{flex-direction: column;}
.medium div, .medium2 div{width: 100%;}
.footer-info p{flex-direction: column; text-align: center;}

}
