/*Global*/
*{outline: none;}
body{background-color: #cccccc; font-family: 'Poppins', sans-serif; margin: 0; padding: 0;}
.container{max-width: 1600px;margin: 0 auto;}
ul{list-style: none;}


/*Animation*/
.last, .mlast{
  /*animation: shake-animation 3s ease infinite;
  transform-origin: 50% 50%;*/
  animation-name: pulse_animation;
  animation-duration: 5000ms;
  transform-origin:70% 70%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}


/* @keyframes shake-animation {
  0% { transform:translate(0,0) }
 1.78571% { transform:translate(5px,0) }
 3.57143% { transform:translate(0,0) }
 5.35714% { transform:translate(5px,0) }
 7.14286% { transform:translate(0,0) }
 8.92857% { transform:translate(5px,0) }
 10.71429% { transform:translate(0,0) }
 100% { transform:translate(0,0) }
} */

@keyframes pulse_animation {
  0% { transform: scale(1); }
  30% { transform: scale(1); }
  40% { transform: scale(1.08); }
  50% { transform: scale(1); }
  60% { transform: scale(1); }
  70% { transform: scale(1.05); }
  80% { transform: scale(1); }
  100% { transform: scale(1); }
}


/*Header*/
header{width: 100%; padding: 15px 0px 30px 0px; position: fixed; z-index: 2;}
header ul{display: flex;flex-direction: row;justify-content: space-between;gap: 100px;}
header ul li a{color: #FFFFFF; text-transform: uppercase; font-size: 16px; line-height: 26px; text-decoration: none; font-weight: 600; cursor: pointer;}
header ul li a:hover{color: #2F5532;}
.last a{color: #ffffff; background-color: #28AA41; padding: 0px 15px; border-radius: 50px; text-decoration: none;}
.last a:hover{color: #FFFFFF !important;}
.menu{display: flex; align-items: center; justify-content: space-between;}
.logo{width: 149px; height: auto;}
.logo img{width: 120%;}
.scrolled-header{background-color: #17501b; border-radius: 50px; padding: 7px 15px;}
.scrolled-header ul li a:hover{color: #28AA41;}
.scrolled-logo{width: 129px; height: auto; padding: 10px;}
.scrolled-logo img{width: 100%;}
.mobile, .header-mobile-inative{display: none;}
.whatsapp-icon{top: 4px; left: -4px; position: relative;}
.menu-mobile{display: none !important;}

/*Responsivo*/
@media (min-width: 1400px) and (max-width: 1599px){
.container {max-width: 1400px;margin: 0 auto;}
header ul{gap: 50px;}
}

@media (min-width: 1200px) and (max-width: 1399px){
.container{max-width: 1200px;margin: 0 auto;}
header ul{gap: 25px;}
header ul li{font-size: 18px;line-height: 28px;}
}

@media (max-width: 1199px){
header{background-color: #17501b; padding: 15px 0px 5px 0px; z-index: 99;}
.container {max-width: 100%;margin: 0 10px;}
.header{background-color: #17501b;}
header ul li{font-size: 15px; line-height: 25px;}
.active{height: 100%;}
.header-mobile-inative ul{display: none;}
.header-mobile-active ul{display: flex;flex-direction: column;justify-content: left; gap: 50px; padding: 0px;}
.mobile {display: flex; justify-content: space-between;}
.menu{display: none;}
/*section, footer h1 {padding-top: 90px !important;}*/
.header-mobile-active ul li a:hover, .header-mobile-active ul li a:hover svg{color: #28AA41 !important;}
.logo img{width: 100%;}


}

